<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <div class="h5">
        {{ title }}
      </div>

      <div>
        <b-button
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          :disabled="isSaving || ishideLoading"
          title="Закрыть"
          variant="secondary"
          class="mr-2"
          @click="onClickHide"
        >
          <b-icon icon="dash" />
        </b-button>

        <b-button
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          title="Закрыть"
          variant="danger"
          @click="onClose"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
    </template>
    <div
      class="appeal-content d-flex justify-center"
    >
      <div
        style="max-width: calc(100% - 154px - 1.5rem)"
        class="w-100"
      >
        <div class="d-flex mb-2">
          <div style="min-width: 270px;">
            <b-form-group
              label="Дата и время"
            >
              <b-form-input
                :value="getFormatDate(form.date, 'dd.MM.yyyy HH:mm')"
                type="text"
                size="sm"
                disabled
              />
            </b-form-group>
          </div>

          <b-form-group
            label="Цель обращения"
            class="ml-5"
          >
            <div class="d-flex flex-wrap">
              <div
                :key="APPEALS_PURPOSES_TEXTS[0].id"
                class="w-50"
              >
                <b-form-radio
                  v-model="$v.form.newPurpose.$model"
                  name="purpose-radios"
                  :value="APPEALS_PURPOSES_TEXTS[0].id"
                >
                  {{ APPEALS_PURPOSES_TEXTS[0].title }}
                </b-form-radio>
              </div>
            </div>
          </b-form-group>
        </div>
        <b-form-group
          label="Категория обращения"
          :invalid-feedback="null"
        >
          <b-form-select
            :value="$v.form.categoryId.$model"
            :state="null"
            disabled
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="categories[0].id"
              >
                {{ categories[0].name }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <AppealType
          v-if="show.type"
          v-model="$v"
          :v="$v"
          :errors-validation="errorsValidation"
          :showss="show"
          :types="selectedCategory.types"
          :subtypes="subtypes"
          :type-another-text-show="show.typeAnotherText"
          class="mb-2"
        />

        <AppealPatientInfo
          :person="person"
          class="mb-2"
        />

        <b-form-group
          v-if="show.files"
          label="Файлы"
        >
          <div
            v-if="documents.length"
            class="mb-3"
          >
            <base-file
              v-for="document in documents"
              :key="document.id"
              :file="document"
              file-api="File/"
              edit
              class="mb-2"
              @delete="onClickDeleteFiles(document)"
            />
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            @click="openAddDocumentsModal"
          >
            Добавить файлы
          </b-button>
        </b-form-group>

        <b-form-group
          v-if="show.comment"
          label="Комментарий"
          :invalid-feedback="errorsValidation.comment[0]"
        >
          <b-form-textarea
            v-model="$v.form.comment.$model"
            :state="$v.$anyError ? !$v.form.comment.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.status"
          label="Статус обращения"
          :invalid-feedback="errorsValidation.status[0]"
        >
          <b-form-select
            v-model="$v.form.status.$model"
            :options="appealStatusOptions"
            :state="$v.$anyError ? !$v.form.status.$error : null"
            text-field="title"
            value-field="id"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.responsible"
          label="Ответственный"
        >
          <b-form-input
            :value="appealResponsible"
            type="text"
            disabled
            size="sm"
          />
        </b-form-group>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import { format } from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { required } from 'vuelidate/lib/validators';
import { showDefaultErrorMessage, showSuccessMessage, showValidationErrorMessage } from '@/helpers/messages';

import {
  APPEAL_STATUSES,
  APPEALS_CATEGORIES,
  APPEALS_PURPOSES,
  APPEALS_PURPOSES_TEXTS,
  APPEALS_TYPES,
} from '@/services/appeals/appeals.const';
import { appealsService } from '@/services';

import { ROLES } from '@/helpers/roles';
import { BaseFile } from '@/components/base';
import AppealPatientInfo from '@/components/domains/appeals/AppealEditorModalNew/AppealPatientInfo';
import AppealType from '@/components/domains/appeals/AppealEditorModalNew/AppealType';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';

export default {
  name: 'AppealEditorModal',
  components: {
    BaseFile,
    AppealPatientInfo,
    AppealType,
  },
  mixins: [validationMixin, mixinRoles],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Создание обращения',
    },
    appealId: {
      type: [String, Number],
      default: null,
    },
    personId: {
      type: [String, Number],
      default: null,
    },
    comment: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    panelTabAppealData: {
      type: Object,
      default: () => ({}),
    },
    fileApi: {
      type: String,
      default: '',
    },
    initDoctorData: {
      type: Object,
      default: null,
    },
    afterSaveFunc: {
      type: Function,
      default: () => {},
    },
    panelTabId: {
      type: [String, Number],
      default: null,
    },
    infoAppeal: {
      type: Object,
      default: null,
    },
    documentsInModal: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    initProcess: false,
    ishideLoading: false,
    categoriesAndTypesLoading: false,
    isSaving: false,
    savedToTabPanel: false,
    APPEALS_PURPOSES_TEXTS,
    stageOne: [
      'status',
      'categoryId',
      'typeNewId',
      'categoryAnotherText',
      'typeAnotherText',
      'comment',
      'fileIds',
      'responsible',
      'personId',
      'type',
      'subtype',
      'documents',
    ],
    documents: [],
    initAppealData: null,
    person: null,
    form: {
      isFinalStage: false,
      newPurpose: 0,
      categoryId: 1,
      status: null,
      typeNewId: null,
      comment: null,
      fileIds: [],
      service: null,
      categoryAnotherText: null,
      typeAnotherText: null,
      date: new Date().toISOString(),
    },
    categories: [
      {
        id: 1,
        name: 'Общая информация',
        purpose: 0,
      },
    ],
    appealStatusOptions: [
      {
        id: null,
        title: 'Выберите статус',
      },
      {
        id: 1,
        title: 'Отложено',
      },
      {
        id: 2,
        title: 'Закрыто',
      },
    ],
    subtypes: [],
  }),
  validations() {
    return {
      form: {
        newPurpose: { required },
        categoryId: this.categoryIdValidation,
        categoryAnotherText: this.categoryAnotherTextValidation,
        typeNewId: this.typeNewIdValidation,
        typeAnotherText: this.typeAnotherTextValidation,
        comment: { required },
        status: { required },
        service: this.serviceValidation,
      },
    };
  },
  computed: {
    isAdministrator() {
      return this.checkRoleAccess([ROLES.ADMINISTRATOR]);
    },
    isQualitiesOfDoctors() {
      return this.checkRoleAccess([ROLES.QUALITIES_OF_DOCTORS]);
    },
    isMedicalCurator() {
      return this.checkRoleAccess([ROLES.MEDICAL_CURATOR]);
    },
    appealResponsible() {
      if (this.initAppealData && this.initAppealData.responsible?.displayName) {
        return this.initAppealData.responsible.displayName;
      }
      return this.$store.state.Auth.user.displayName;
    },
    stages() {
      try {
        return [
          {
            id: 1,
            description: '',
            active: false,
            permissions:
              this.isAdministrator
              || this.isMedicalCurator
              || this.isQualitiesOfDoctors,
            load: true,
          },
        ];
      } catch (e) {
        console.error(e);
      }
      return [];
    },

    errorsValidation() {
      const errors = {};

      errors.categoryId = [];
      if (!this.$v.form.categoryId.required) {
        errors.categoryId.push('Поле "категория" обязательно для заполнения');
      }

      errors.categoryAnotherText = [];
      if (!this.$v.form.categoryAnotherText.required) {
        errors.categoryAnotherText.push('Поле "другая категория обращения" обязательно для заполнения');
      }

      errors.typeNewId = [];
      if (!this.$v.form.typeNewId.required) {
        errors.typeNewId.push('Поле "тип" обязательно для заполнения');
      }

      errors.comment = [];
      if (!this.$v.form.comment.required) {
        errors.comment.push('Поле "комментарий" обязательно для заполнения');
      }

      errors.status = [];
      if (!this.$v.form.status.required) {
        errors.status.push('Поле "статус" обязательно для заполнения');
      }

      return errors;
    },
    errors() {
      return Object.entries(this.$v.form).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value !== null) {
          if (value.$anyError) {
            acc.push(key);
          }
        }

        return acc;
      }, []);
    },
    categoryIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.category) {
        validation.required = required;
      }

      return validation;
    },
    categoryAnotherTextValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.categoryAnotherText) {
        validation.required = required;
      }

      return validation;
    },
    typeNewIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.type) {
        validation.required = required;
      }

      return validation;
    },
    typeAnotherTextValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.typeAnotherText) {
        validation.required = required;
      }

      return validation;
    },
    serviceValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.service) {
        validation.required = required;
      }

      return validation;
    },
    agreedServicesValidation() {
      const validation = {
        required: () => true,
      };

      return validation;
    },
    notAgreedServicesValidation() {
      const validation = {
        required: () => true,
      };

      return validation;
    },
    regectionReasonValidation() {
      const validation = {
        required: () => true,
      };

      return validation;
    },
    doctorCommentValidation() {
      const validation = {
        required: () => true,
      };

      return validation;
    },
    purposeId() {
      return this.form.newPurpose;
    },
    selectedPurpose() {
      return APPEALS_PURPOSES_TEXTS.find((item) => item.id === this.purposeId);
    },
    selectedPurposeInformation() {
      return this.selectedPurpose?.id === APPEALS_PURPOSES.Information;
    },
    selectedCategory() {
      return this.categories.find((item) => item.id === this.form.categoryId);
    },
    selectedCategoryAnalyzes() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.InformationAnalyzes;
    },
    selectedType() {
      return this.selectedCategory?.types?.find((item) => item.id === this.form.typeNewId);
    },
    selectedTypeRefundByAnalysis() {
      return this.selectedType?.name === APPEALS_TYPES.Analysis;
    },
    selectedStatusTransmitted() {
      return this.form.status === APPEAL_STATUSES.InProgress;
    },
    showTypeAnotherText() {
      return this.showByStage(['typeAnotherText'])
        && this.selectedType?.name === APPEALS_TYPES.Other;
    },
    showCategory() {
      return this.showByStage(['categoryId']);
    },
    showCategoryAnotherText() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.Other;
    },
    showType() {
      return this.showByStage(['type', 'subtype'])
        && this.selectedCategory?.types?.length;
    },
    showSubtype() {
      return this.form.typeNewId
        && this.selectedType?.subtypes?.length;
    },
    showComment() {
      return this.showByStage(['comment']);
    },
    showStatus() {
      return this.showByStage(['status']);
    },
    showResponsible() {
      return this.showByStage(['responsible']);
    },
    showFiles() {
      return this.showByStage(['fileIds']);
    },
    showCommentAndStatus() {
      return this.showByStage(['status', 'category']);
    },
    show() {
      return {
        category: this.showCategory,
        categoryAnotherText: this.showCategoryAnotherText,
        type: this.showType,
        typeAnotherText: this.showTypeAnotherText,
        subtype: this.showSubtype,
        comment: this.showComment,
        status: this.showStatus,
        responsible: this.showResponsible,
        files: this.showFiles,
      };
    },
    rolePermission() {
      return this.isAdministrator || this.isMedicalCurator;
    },
  },
  async mounted() {
    if (this.initDoctorData) {
      this.person = {
        firstName: this.initDoctorData.firstName,
        lastName: this.initDoctorData.lastName,
        middleName: this.initDoctorData.middleName,
        phoneNumber: this.initDoctorData.phoneNumber,
        birthDate: this.initDoctorData.birthDate ? this.getFormatDate(this.initDoctorData.birthDate, 'yyyy-MM-dd') : null,
      };
    }
    if (this.appealId) await this.initAppeal(this.appealId);
    if (this.infoAppeal?.status) {
      this.form.status = this.infoAppeal.status;
    }
    if (this.comment === null || !this.comment || this.infoAppeal?.comment) this.form.comment = this.infoAppeal?.comment;
    if (this.documentsInModal !== null && this.documentsInModal.length) this.documents = this.documentsInModal;
  },
  beforeDestroy() {
    if (!this.savedToTabPanel && this.panelTabId) {
      this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
      this.$store.commit(this.$types.TAB_PANEL_ITEM_REMOVE_BY_ID, this.panelTabId);
    }
  },
  methods: {
    onClickHide() {
      if (this.ishideLoading) return;
      this.ishideLoading = true;

      try {
        this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
        const mutationName = 'TAB_PANEL_ITEM_ADD';
        const tabData = {
          id: this.panelTabId || Date.now(),
          title: this.title ? this.title : 'Новое обращение',
          type: 0, // appeal type
          data: {
            formData: this.form,
            appealId: this.appealId,
            personId: this.personId,
            documents: this.documents,
            person: this.person,
          },
        };
        this.$store.commit(this.$types[mutationName], tabData);
        this.savedToTabPanel = true;
      } catch (e) {
        console.error(e);
        this.ishideLoading = false;
      } finally {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('input', false);
    },
    takeCategoryByName(categoryName) {
      return this.categories.find((item) => item.name === categoryName);
    },
    getFormatDate(date, formatter) {
      return date && formatter ? format(new Date(date), formatter) : null;
    },
    showByStage(formKeys) {
      try {
        let show = false;
        formKeys.forEach((formKey) => {
          const status = this.stageOne?.some((item) => item === formKey);
          if (status) show = true;
        });

        return show;
      } catch (e) {
        console.error(e);
      }

      return false;
    },
    fetchPerson() {
      this.person = {
        firstName: this.initDoctorData.firstName,
        lastName: this.initDoctorData.lastName,
        middleName: this.initDoctorData.middleName,
        phoneNumber: this.initDoctorData.phoneNumber,
        birthDate: this.initDoctorData.birthDate ? this.getFormatDate(this.initDoctorData.birthDate, 'yyyy-MM-dd') : null,
      };
    },
    saveFilesCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData?.guid,
        });
        return null;
      });
    },
    onClickDeleteFiles(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    openAddDocumentsModal() {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка файлов',
          saveFileCallback: this.saveFilesCallback,
        },
      });
    },
    clearFieldsByShow() {
      if (!this.show.category) {
        this.form.categoryId = null;
        this.form.categoryAnotherText = null;
      }
      if (!this.show.categoryAnotherText) this.form.categoryAnotherText = null;
      if (!this.show.type) {
        this.form.typeNewId = null;
        this.form.typeAnotherText = null;
      }
      if (!this.show.typeAnotherText) this.form.typeAnotherText = null;
      if (!this.show.subtype) this.form.subtype = null;
    },
    clearFieldsOnChangeCategory() {
      if (!this.initProcess) {
        this.form.typeNewId = null;
        this.form.typeAnotherText = null;
      }
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        this.clearFieldsByShow();
        let appealResponsibleId = this.form.responsible;

        if (!appealResponsibleId) {
          appealResponsibleId = this.$store.state.Auth.user.userId;
        }

        const data = {
          personId: this.personId,
          responsible: appealResponsibleId,
          chatAppealId: this.appealId,
          ...this.form,
        };

        if (this.show.files) {
          data.fileIds = this.documents.map((item) => item.guid);
        }
        if (this.initAppealData) {
          const appealData = {
            ...data,
            id: this.initAppealData.id,
          };

          await this.editNewAppeal(appealData);
        } else await this.createNewAppeals(data);

        this.isSaving = false;
        this.$store.commit('Appeal/TOGGLE_APPEAL_CREATED_TRIGGER'); // сделает тогл триггера и обновит обращения
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isSaving = false;
      }
    },
    async createNewAppeals(data) {
      try {
        const response = await appealsService.create(data);
        showSuccessMessage(response.id, 'создано');
        return response;
      } catch (err) {
        console.log(err);
        showDefaultErrorMessage('создании');
      }
    },
    async initAppeal(appealId) {
      const data = await appealsService.getById(appealId);
      this.initAppealData = JSON.parse(JSON.stringify(data));
      this.form.isFinalStage = data.isFinalStage;
      this.form.categoryId = data.category?.id;
      this.form.typeNewId = data.type?.id;
      this.form.policyId = data.policy?.id;
      this.form.cityId = data.city?.id;
      this.form.checkupProfileId = data.checkupProfile?.id;
      this.form.checkupAnotherText = data.checkupAnotherText;
      this.form.clinicId = data.clinic?.id;
      this.form.consultationReason = data.consultationReason;
      this.form.consultationId = data.consultationId;
      this.form.consultationComment = data.consultationComment;
      this.form.comment = data.comment;
      this.form.categoryAnotherText = data.categoryAnotherText;
      this.form.typeAnotherText = data.typeAnotherText;
      this.form.newPurpose = data.purpose;
      this.form.techComment = data.techComment;
      this.form.yandexLink = data.yandexLink;
      this.form.urgency = data.urgency;
      this.form.transportation = data.transportation;
      this.form.status = this.infoAppeal?.status ? this.infoAppeal?.status : data.status;
      this.form.gemotestAppealId = data.gemotestAppealId;
      this.form.isActivePolicy = data.isActivePolicy;
      this.form.operatorComment = data.operatorComment;
      this.form.orderNumber = data.orderNumber;
      this.form.patientInfo = data.patientInfo;
      this.form.administratorName = data.administratorName;
      this.form.reconciliations = data.reconciliations;
      this.form.reconciliationComment = data.reconciliationComment;
      this.form.rejectionReason = data.rejectionReason;
      this.form.responsible = data.responsible?.id;
      this.form.date = new Date(utc0ToDateWithUserTimezone(data.dateTime));
      this.form.insuranseCompanyId = data.insuranseCompany?.id;

      if (data.files) {
        this.documents = data.files.map((item) => ({ title: item.name, guid: item.id }));
      }
    },
    async editNewAppeal(obj) {
      try {
        const response = await appealsService.update(obj);
        showSuccessMessage(response.id, 'отредактировано');
        return response;
      } catch (err) {
        console.log(err);
        showDefaultErrorMessage('редактровании');
      }
    },
  },
};
</script>
